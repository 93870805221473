import { createApp, defineAsyncComponent, ref } from "vue";
import "../css/app.css";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import axios from "axios";
import VueAxios from "vue-axios";

window.bugsnag = Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
});

window.frontEndToken = import.meta.env.VITE_AUTH_FRONTEND;

const bugsnagVue = Bugsnag.getPlugin("vue");


const HeaderCarousel = defineAsyncComponent(() => import("./components/Carousels/HeaderCarousel.vue"));
const ProductImagesCarousel = defineAsyncComponent(() => import("./components/Carousels/ProductImagesCarousel.vue"));
const RolexCarousel = defineAsyncComponent(() => import("./components/Carousels/RolexLegacyCarousel.vue"));
const DiscoverCarousel = defineAsyncComponent(() => import("./components/Carousels/DiscoverCarousel.vue"));
const TagCollectionsCarousel = defineAsyncComponent(() => import("./components/Carousels/TagCollectionsCarousel.vue"));
const TagProductImagesCarousel = defineAsyncComponent(() => import("./components/Carousels/TagProductImagesCarousel.vue"));


const AddToCartForm = defineAsyncComponent(() => import("./components/Products/AddToCartForm.vue"));
const ProductAction = defineAsyncComponent(() => import("./components/Products/ProductAction.vue"));
const EnquireForm = defineAsyncComponent(() => import("./components/Forms/Enquire.vue"));
const ReserveForm = defineAsyncComponent(() => import("./components/Forms/Reserve.vue"));
const Cart = defineAsyncComponent(() => import("./components/Cart/Cart.vue"));
const JewelleryFilters = defineAsyncComponent(() => import("./components/Filters/JewelleryFilters.vue"));
const WatchFilters = defineAsyncComponent(() => import("./components/Filters/WatchFilters.vue"));
const GiftsFilters = defineAsyncComponent(() => import("./components/Filters/GiftsFilters.vue"));
const BrandDescription = defineAsyncComponent(() => import("./components/Components/BrandDescription.vue"));
const Finance = defineAsyncComponent(() => import("./components/Products/Finance.vue"));
const CheckoutCalculator = defineAsyncComponent(() => import("./components/Cart/CheckoutCalculator.vue"));
const RolexOpeningHours = defineAsyncComponent(() => import("./components/Components/RolexOpeningHours.vue"));
const FullSearch = defineAsyncComponent(() => import("./components/Search/Search.vue"));
const SearchFilters = defineAsyncComponent(() => import("./components/SearchComponents/Filters.vue"));
const RolexClock = defineAsyncComponent(() => import("./components/Components/RolexClock.vue"));
const CheckoutFinance = defineAsyncComponent(() => import("./components/Cart/Finance.vue"));
const OrderNote = defineAsyncComponent(() => import("./components/Forms/OrderNote.vue"));
const Sale = defineAsyncComponent(() => import("./components/Sale/Sale.vue"));
const JewelleryCollectionCard = defineAsyncComponent(() => import("./components/Jewellery/CollectionCard.vue"));
const JewelleryCollectionsCarousel = defineAsyncComponent(() => import("./components/Carousels/JewelleryCollectionsCarousel.vue"));
const JewelleryFeaturedProductsCarousel = defineAsyncComponent(() => import("./components/Carousels/JewelleryFeaturedProductsCarousel.vue"));

const RolexLoadMore = defineAsyncComponent(() => import("./components/Components/RolexLoadMore.vue"));
const RolexMainMenu = defineAsyncComponent(() => import("./components/Navigation/RolexMainMenu.vue"));
const RolexKeepExploringCarousel = defineAsyncComponent(() => import("./components/Carousels/RolexKeepExploring.vue"));
const RolexHeroCarousel = defineAsyncComponent(() => import("./components/Carousels/RolexHeroCarousel.vue"));
const RolexContactForm = defineAsyncComponent(() => import("./components/Forms/RolexContactForm.vue"));
const RolexModelContactForm = defineAsyncComponent(() => import("./components/Forms/RolexModelContactForm.vue"));
const RolexMap = defineAsyncComponent(() => import("./components/Maps/RolexMap.vue"));


import PreCheckout from "./components/Modals/PreCheckout.vue";
import MobileNavigation from "./components/Navigation/MobileNavigation.vue";
import SmallCart from "./components/Cart/SmallCart.vue";
import SearchForm from "./components/Search/SearchForm.vue";
import VueGoogleMaps from "vue-google-maps-community-fork";

const app = createApp({
  components: {
    HeaderCarousel,
    MobileNavigation,
    SmallCart,
    ProductImagesCarousel,
    AddToCartForm,
    EnquireForm,
    ReserveForm,
    ProductAction,
    Cart,
    JewelleryFilters,
    WatchFilters,
    GiftsFilters,
    BrandDescription,
    RolexCarousel,
    Finance,
    RolexOpeningHours,
    SearchForm,
    FullSearch,
    SearchFilters,
    RolexClock,
    CheckoutFinance,
    OrderNote,
    PreCheckout,
    Sale,
    JewelleryCollectionCard,
    JewelleryCollectionsCarousel,
    JewelleryFeaturedProductsCarousel,
    DiscoverCarousel,
    CheckoutCalculator,
    RolexMainMenu,
    RolexKeepExploringCarousel,
    RolexHeroCarousel,
    RolexLoadMore,
    RolexContactForm,
    RolexModelContactForm,
    RolexMap,
    TagCollectionsCarousel,
		TagProductImagesCarousel,
  },
  setup() {
    const navOpen = ref(false);
    const cartOpen = ref(false);
    const lastUpdateTimestamp = ref(Date.now());
    const showPreCheckout = ref(false);
    const showCheckoutFinanceCalculator = ref(true);

    function updateTimestamp() {
      lastUpdateTimestamp.value = Date.now();
    }

    function checkout() {
      showPreCheckout.value = true;
    }

    const trackRolexPhone = () => {
      if (typeof window._satellite !== "undefined") {
        window._satellite.track("contactCall");
        return;
      }
      console.log("would have triggered contactCall but window._satellite not defined");
    }

    const trackRolexDirections = () => {
      console.log('hello');
      if (typeof window._satellite !== "undefined") {
        window._satellite.track("getDirections");
        return;
      }
      console.log("would have triggered getDirections but window._satellite not defined");
    }

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    return {
      navOpen,
      cartOpen,
      lastUpdateTimestamp,
      updateTimestamp,
      showPreCheckout,
      checkout,
      trackRolexPhone,
      trackRolexDirections,
      showCheckoutFinanceCalculator,
      scrollToTop
    };
  },
});
import { VueReCaptcha  } from "vue-recaptcha-v3";

if (window.location.pathname !== "/") {
  VueReCaptcha.install(app, {
    siteKey: import.meta.env.VITE_RECAPTCHA_SITEKEY,
    loaderOptions: {
      autoHideBadge: true,
    }
  });
}

app
  .use(bugsnagVue)
  .use(VueAxios, axios)
  .provide("axios", app.config.globalProperties.axios)
  .use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_MAP_KEY,
      v: 3.57,
      loading: 'async',
    },
  })
  .mount("#app");
